const { Paths } = require('./paths');

const devPaths = [
  Paths.Home,
  Paths.ProductsOverview,
  Paths.PDP,
  Paths.PCP,
  Paths.HotDeals,
  Paths.HowItWorks,
  Paths.ForBusiness,
  Paths.Cluster,
  Paths.UserPanelSubscriptions,
  Paths.UserPanelInvoices,
  Paths.UserPanelReferAFriend,
  Paths.UserPanelHelp,
  Paths.UserPanelPersonalDetails,
  Paths.UserPanelPreferences,
  Paths.UserPanelChangeMarketingOptin,
  Paths.UserPanelMovingForm,
  Paths.UserPanelSubscriptionCancel,
  Paths.UserPanelChangeAddress,
  Paths.UserPanelChangePassword,
  Paths.UserPanelChangePaymentMethod,
  Paths.MyBluemovement,
  Paths.Faq,
  Paths.NewMandate,
  Paths.Landing,
  Paths.ForgotPassword,
  Paths.ResetPassword,
  Paths.Login,
  Paths.TermsAndConditionsDE,
  Paths.TermsAndConditionsNL,
  Paths.PrivacyPolicyDE,
  Paths.PrivacyPolicyNL,
  Paths.CompanyDetails,
  Paths.Cookies,
  Paths.Contact,
  Paths.CountrySelector,
  Paths.AboutUs,
  Paths.DevComponents,
  Paths.DevButtons,
  Paths.CreatePaymentLink,
  Paths.ConfirmationPaymentLink,
  Paths.UserPanelSubscriptionDetails,
  Paths.UserPanelChangeAddress,
  Paths.UserPanelTakeover,
  Paths.UserPanelTakeoverOverview,
  Paths.UserPanelTakeoverReject,
  Paths.UserPanelTakeoverUnpaidInvoices,
  Paths.Sitemap,
  Paths.UserPanelSubscriptionRepair,
  Paths.DevLogin,
  Paths.Info,
  // TO DO: REMOVE AFTER ALL OLD TAKEOVERS ARE EXPIRED
  Paths.UserPanelTakeoverReceiverOld,
  Paths.AcceptanceOfCommercialApproach,
  Paths.IdConfirmation,
  Paths.Blog,
  Paths.BlogPostNL,
  Paths.BlogPostDE,
  Paths.CheckoutOrderPreview,
  Paths.CheckoutCustomerDetails,
  Paths.CheckoutDelivery,
  Paths.CheckoutPayment,
  Paths.CheckoutPaymentStatus,
  Paths.CheckoutOrderConfirmation,
  Paths.CheckoutOrderNotAccepted,
  Paths.CheckoutCreditCheck,
  Paths.UserPanelChangeSubscription,
  Paths.UserPanelRenewalSubscription,
  Paths.PaymentStatus,
  Paths.Cart,
];

module.exports = {
  devPaths,
};
